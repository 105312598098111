import { TableProps } from "antd"
import { formatCurrency, formatNumber } from "../../utils/string-utils"

export const dataTypeColumns: TableProps<any>['columns'] = [
    {
        key: 'id',
        title: 'STT',
        render: (_text, _record, index) => index + 1,
    },
    {
        key: 'dataType',
        title: "Nhóm gói cước",
        dataIndex: 'dataType',
        render: dataType => renderType(dataType),
    },
    {
        key: 'total_pack',
        title: "Tổng số gói",
        dataIndex: 'detail',
        render: detail => detail.length,
    },
    {
        key: 'totalAmount_current',
        title: "Đã bán trong tháng",
        dataIndex: 'totalAmount_current',
        render: totalAmount_current => formatNumber(Number(totalAmount_current || 0)),
    },
    {
        key: 'totalAmount_previous',
        title: "Đã bán tháng trước",
        dataIndex: 'totalAmount_previous',
        render: totalAmount_previous => formatNumber(Number(totalAmount_previous || 0)),
    },
    {
        key: 'totalAmount',
        title: "Chênh lệch số lượng",
        render: (_, data: any) => formatNumber(Number(data.totalAmount_current || 0) - Number(data.totalAmount_previous || 0)),
    },
    {
        key: 'count_current',
        title: "Doanh thu trong tháng",
        dataIndex: 'count_current',
        render: count_current => formatCurrency(Number(count_current || 0)),
    },
    {
        key: 'count_previous',
        title: "Doanh thu tháng trước",
        dataIndex: 'count_previous',
        render: count_previous => formatCurrency(Number(count_previous || 0)),
    },
    {
        key: 'count',
        title: "Chênh lệch doanh thu",
        render: (_, data: any) => formatNumber(Number(data.count_current || 0) - Number(data.count_previous || 0)),
    },
]

export const dataTypeDayColumns: TableProps<any>['columns'] = [
    {
        key: 'id',
        title: 'STT',
        render: (_text, _record, index) => index + 1,
    },
    {
        key: 'dataType',
        title: "Nhóm gói cước",
        dataIndex: 'dataType',
        render: dataType => renderType(dataType),
    },
    {
        key: 'total_pack',
        title: "Tổng số gói",
        dataIndex: 'detail',
        render: detail => detail.length,
    },
    {
        key: 'totalAmount_current',
        title: "Đã bán trong ngày",
        dataIndex: 'totalAmount_current',
        render: totalAmount_current => formatNumber(Number(totalAmount_current || 0)),
    },
    {
        key: 'totalAmount_previous',
        title: "Ngày trong tháng trước",
        dataIndex: 'totalAmount_previous',
        render: totalAmount_previous => formatNumber(Number(totalAmount_previous || 0)),
    },
    {
        key: 'totalAmount',
        title: "Chênh lệch số lượng",
        render: (_, data: any) => formatNumber(Number(data.totalAmount_current || 0) - Number(data.totalAmount_previous || 0)),
    },
    {
        key: 'count_current',
        title: "Doanh thu trong ngày",
        dataIndex: 'count_current',
        render: count_current => formatCurrency(Number(count_current || 0)),
    },
    {
        key: 'count_previous',
        title: "Ngày trong tháng trước",
        dataIndex: 'count_previous',
        render: count_previous => formatCurrency(Number(count_previous || 0)),
    },
    {
        key: 'count',
        title: "Chênh lệch doanh thu",
        render: (_, data: any) => formatNumber(Number(data.count_current || 0) - Number(data.count_previous || 0)),
    },
]

export const renderPackageMonthColumns = (detailPackage: any) => {
    const packageMonthColumns: TableProps<any>['columns'] = [
        {
            render: (_text, _record, index) => index + 1,
        },
        {
            key: 'packageName',
            title: "Tên gói cước",
            dataIndex: 'packageName',
        },
        {
            key: 'dataPack',
            title: "Mã gói cước",
            dataIndex: 'dataPack',
        },
        {
            key: 'price',
            title: "Giá bán",
            dataIndex: 'price',
            render: price => formatCurrency(price),
        },
        {
            key: 'totalAmount_current',
            title: "Đã bán trong tháng",
            dataIndex: 'totalAmount_current',
            render: totalAmount_current => formatNumber(Number(totalAmount_current) || 0),
        },
        {
            key: 'totalAmount_previous',
            title: "Đã bán tháng trước",
            dataIndex: 'totalAmount_previous',
            render: totalAmount_previous => formatNumber(Number(totalAmount_previous) || 0),
        },
        {
            key: 'totalAmount',
            title: "Chênh lệch số lượng",
            render: (_, data: any) => formatNumber(Number(data.totalAmount_current || 0) - Number(data.totalAmount_previous || 0)),
        },
        {
            key: 'count_current',
            title: "Doanh thu trong tháng",
            dataIndex: 'count_current',
            render: count_current => formatCurrency(Number(count_current || 0)),
        },
        {
            key: 'count_previous',
            title: "Doanh thu tháng trước",
            dataIndex: 'count_previous',
            render: count_previous => formatCurrency(Number(count_previous || 0)),
        },
        {
            key: 'count',
            title: "Chênh lệch doanh thu",
            render: (_, data: any) => formatNumber(Number(data.count_current || 0) - Number(data.count_previous || 0)),
        },
        {
            key: 'total_day',
            title: "Số ngày",
            dataIndex: 'detail',
            render: detail => detail.current?.length,
        },
        {
            key: 'action',
            title: "Chi tiết",
            render: (_, data: any) => detailPackage(data),
        },
    ]
    return packageMonthColumns
}

export const packageDayColumns: TableProps<any>['columns'] = [
    {
        render: (_text, _record, index) => index + 1,
    },
    {
        key: 'packageName',
        title: "Tên gói cước",
        dataIndex: 'packageName',
    },
    {
        key: 'dataPack',
        title: "Mã gói cước",
        dataIndex: 'dataPack',
    },
    {
        key: 'price',
        title: "Giá bán",
        dataIndex: 'price',
        render: price => formatCurrency(price),
    },
    {
        key: 'totalAmount_current',
        title: "Đã bán trong ngày",
        dataIndex: 'totalAmount_current',
        render: totalAmount_current => formatNumber(Number(totalAmount_current) || 0),
    },
    {
        key: 'totalAmount_previous',
        title: "Ngày trong tháng trước",
        dataIndex: 'totalAmount_previous',
        render: totalAmount_previous => formatNumber(Number(totalAmount_previous) || 0),
    },
    {
        key: 'totalAmount',
        title: "Chênh lệch số lượng",
        render: (_, data: any) => formatNumber(Number(data.totalAmount_current || 0) - Number(data.totalAmount_previous || 0)),
    },
    {
        key: 'count_current',
        title: "Doanh thu trong ngày",
        dataIndex: 'count_current',
        render: count_current => formatCurrency(Number(count_current || 0)),
    },
    {
        key: 'count_previous',
        title: "Ngày trong tháng trước",
        dataIndex: 'count_previous',
        render: count_previous => formatCurrency(Number(count_previous || 0)),
    },
    {
        key: 'count',
        title: "Chênh lệch doanh thu",
        render: (_, data: any) => formatNumber(Number(data.count_current || 0) - Number(data.count_previous || 0)),
    },
]

export const renderType = (type: string) => {
    switch (type) {
        case 'normal':
            return 'Data sponsor'
        case 'change_price':
            return 'Thay đổi giá'
        case 'bundle_video':
            return 'Bundle Video'
        case 'vtvcab':
            return 'VTVcab'
        case 'tv360':
            return 'TV360'
        default:
            return type
    }
}

export const getInfoChart1 = (statistics: any) => {
    const partners = statistics?.reduce((acc: any, item: any) => {
        if (!acc[item.cpCode]) {
            acc[item.cpCode] = 0
        }
        acc[item.cpCode] = Number(acc[item.cpCode]) + Number(item.totalAmount * item.price)
        return acc
    }, {}) || []
    const total = Object.values(partners).reduce((sum: any, value) => sum + value, 0)
    return Object.entries(partners).sort((a: any, b: any) => b[1] - a[1]).reduce((acc: any, [key, value]) => {
        acc[key] = ((Number(value) / Number(total)) * 100).toFixed(2)
        return acc
    }, {})
}

export const getInfoChart2 = (current: any, previous: any) => {
    const currrentPartners = Object.fromEntries(Object.entries(current?.reduce((acc: any, item: any) => {
        if (!acc[item.cpCode]) {
            acc[item.cpCode] = 0
        }
        acc[item.cpCode] = Number(acc[item.cpCode]) + Number(item.totalAmount * item.price)
        return acc
    }, {})).sort((a: any, b: any) => b[1] - a[1])) || {}
    const previousPartners = Object.fromEntries(Object.entries(previous?.reduce((acc: any, item: any) => {
        if (!acc[item.cpCode]) {
            acc[item.cpCode] = 0
        }
        acc[item.cpCode] = Number(acc[item.cpCode]) + Number(item.totalAmount * item.price)
        return acc
    }, {})).sort((a: any, b: any) => b[1] - a[1])) || {}
    return Object.keys(currrentPartners).map((key) => ({
        name: key,
        current: currrentPartners[key] || 0,
        previous: previousPartners[key] || 0
    }))
}

export const getInfoChart3 = (statistics: any) => {
    const partners = statistics?.reduce((acc: any, item: any) => {
        if (!acc[renderType(item.dataType)]) {
            acc[renderType(item.dataType)] = 0
        }
        acc[renderType(item.dataType)] = Number(acc[renderType(item.dataType)]) + Number(item.totalAmount * item.price)
        return acc
    }, {}) || []
    return Object.fromEntries(Object.entries(partners).sort((a: any, b: any) => b[1] - a[1]))
}

export const getInfoPackages = (statistics: any) => {
    if (!statistics || !Array.isArray(statistics)) return []
    const packages = statistics?.reduce((acc: any, item: any) => {
        if (!acc[item.packageName]) {
            acc[item.packageName] = []
        }
        acc[item.packageName].push({ ...item, count: Number(item.totalAmount * item.price) })
        return acc
    }, {})
    const packagesArray = Object.entries(packages).map((item: any) => {
        const count = item[1].reduce((sum: any, value: any) => sum + value.count, 0)
        const totalAmount = item[1].reduce((sum: any, value: any) => sum + value.totalAmount, 0)
        return {
            dataType: item[1][0].dataType,
            dataPack: item[1][0].dataPack,
            packageName: item[0],
            price: item[1][0].price,
            totalAmount,
            count,
            detail: item[1].sort((a: any, b: any) => a.day - b.day),
        }
    }).sort((a: any, b: any) => a.dataType.localeCompare(b.dataType))
    const data = packagesArray?.reduce((acc: any, item: any) => {
        if (!acc[item.dataType]) {
            acc[item.dataType] = []
        }
        acc[item.dataType].push(item)
        return acc
    }, {})
    return Object.entries(data).map((item: any) => {
        const count = item[1].reduce((sum: any, value: any) => sum + value.count, 0)
        const totalAmount = item[1].reduce((sum: any, value: any) => sum + value.totalAmount, 0)
        return {
            dataType: item[0],
            totalAmount,
            count,
            detail: item[1].sort((a: any, b: any) => a.day - b.day),
        }
    })
}


export const aggregateArrays = (current: any, previous: any) => {
    const map = new Map()
    current.forEach(({ dataType, totalAmount, count, detail }: any) => {
        map.set(dataType, {
            dataType,
            totalAmount_current: totalAmount,
            count_current: count,
            detail: detail.map((item: any) => ({
                dataType: item.dataType,
                packageName: item.packageName,
                dataPack: item.dataPack,
                price: item.price,
                totalAmount_current: item.totalAmount,
                count_current: item.count,
                detail: {
                    current: item.detail
                },
            })),
        })
    })
    previous.forEach(({ dataType, totalAmount, count, detail }: any) => {
        if (map.has(dataType)) {
            map.get(dataType).totalAmount_previous = totalAmount
            map.get(dataType).count_previous = count
            detail.forEach((prev: any) => {
                const detailItem = map.get(dataType).detail.find((item1: any) => item1.packageName === prev.packageName)
                if (detailItem) {
                    detailItem.totalAmount_previous = prev.totalAmount
                    detailItem.count_previous = prev.count
                    detailItem.detail.previous = prev.detail
                }
            })
        }
    })
    return Array.from(map.values())
}
