import APIUtils from './base';
import { ILoginParams } from '../models/login.model';
import { overrideAxiosDefaultConfig } from './config';
import { setupAxiosInterceptors } from './interceptor';
import { PaymentRevenueForPartnerRequest, RefundForPartnerRequest, TopUpBalanceDepositRequest } from '../interfaces/request-params.interface';
import {
    CheckDataCodeRequest,
    CheckTransactionRequest,
    RefundTransactionRequest
} from '../interfaces/transactions.interface';
export const apiLogin = async (params: ILoginParams) => APIUtils.post('/api/auth/login', params);
export const apiGetTokenInfo = () => APIUtils.get('/api/auth/get_token_info');

export const apiGetListPartner = () => APIUtils.get('/api/partner/list');
// Wallet APIs
export const apiGetWalletBalanceTopUpCurrent = (params: { cpCode: string }) => APIUtils.get('/api/wallet/balance_top_up_current', params);
export const apiGetCheckBalance = (params: { cpCode: string }) => APIUtils.get('/api/wallet/check_balance', params);

// Network APIs
export const apiPostTopUpBalanceDeposit = (params: TopUpBalanceDepositRequest) => APIUtils.post('/api/wallet/top_up_balance_deposit', params);
export const apiPostPaymentRevenueForPartner = (params: PaymentRevenueForPartnerRequest) => APIUtils.post('/api/wallet/payment_revenue_for_partner', params);
export const apiPostRefundForPartner = (params: RefundForPartnerRequest) => APIUtils.post('/api/wallet/refund_for_partner', params);

export const apiGetListNetworkProvider = () => APIUtils.get('/api/network/list');

// Transactions APIs
export const apiGetCheckTransaction = (params: CheckTransactionRequest) => APIUtils.get('/api/transaction/check_transaction', params);
export const apiGetCheckTransactionOnDataSponsor = (params: CheckTransactionRequest) => APIUtils.get('/api/transaction/check_transaction_on_data_sponsor', params);
export const apiGetCheckTransactionOnVTVCab = (params: CheckTransactionRequest) => APIUtils.get('/api/transaction/check_transaction_on_vtv_cab', params);
export const apiGetCheckTransactionDataCode = (params: CheckTransactionRequest) => APIUtils.get('/api/transaction/check_transaction_data_code', params);
export const apiRefundTransaction = (params: RefundTransactionRequest) => APIUtils.post('/api/transaction/refund_transaction', params);
export const apiCheckDataCode = (params: CheckDataCodeRequest) => APIUtils.get('/api/transaction/check_data_code', params);
export const apiCheckDataCodeOnDataSponsor = (params: CheckDataCodeRequest) => APIUtils.get('/api/transaction/check_data_code_on_data_sponsor', params);

export const apiGetTransactionRefund = (params: any) => APIUtils.get('/api/transaction/get_transaction_refund', params);

// ---------------
// PackageDataSponsor
export const apiGetPackageDataSponsor = (params?: any) => APIUtils.get('/api/package_data_sponsor/get_package_data_sponsor', params);
export const apiGetListPackageDataSponsor = (params?: any) => APIUtils.get('/api/package_data_sponsor/get_list_package_data_sponsor', params);
export const apiAddPackageDataSponsors = (params?: any) => APIUtils.post('/api/package_data_sponsor/add_package_data_sponsors', params);
export const apiUpdatePackageDataSponsor = (params?: any) => APIUtils.put('/api/package_data_sponsor/update_package_data_sponsor', params);
export const apiGetListPackageDataSponsorsPartnerByParentId = (params?: any) => APIUtils.get('/api/package_data_sponsor/get_list_package_data_sponsor_partner_by_parent_id', params);
// PackageDataSponsorPartner
export const apiGetPackageDataSponsorPartner = (params?: any) => APIUtils.get('/api/package_data_sponsor/get_package_data_sponsor_partner', params);
export const apiGetListPackageDataSponsorPartner = (params?: any) => APIUtils.get('/api/package_data_sponsor/get_list_package_data_sponsor_partner', params);
export const apiAddPackageDataSponsorsPartner = (params?: any) => APIUtils.post('/api/package_data_sponsor/add_package_data_sponsors_partner', params);
export const apiUpdatePackageDataSponsorPartner = (params?: any) => APIUtils.put('/api/package_data_sponsor/update_package_data_sponsor_partner', params);
// PackageDataCode
export const apiGetPackageDataCodeV2 = (params?: any) => APIUtils.get('/api/package_data_code/get_package_data_code', params);
export const apiGetListPackageDataCodes = (params?: any) => APIUtils.get('/api/package_data_code/get_list_package_data_codes', params);
export const apiAddPackageDataCodes = (params?: any) => APIUtils.post('/api/package_data_code/add_package_data_codes', params);
export const apiUpdatePackageDataCode = (params?: any) => APIUtils.put('/api/package_data_code/update_package_data_code', params);
// PackageDataCodePartner
export const apiGetPackageDataCodePartner = (params?: any) => APIUtils.get('/api/package_data_code/get_package_data_code_partner', params);
export const apiGetListPackageDataCodesPartner = (params?: any) => APIUtils.get('/api/package_data_code/get_list_package_data_codes_partner', params);
export const apiAddPackageDataCodesPartner = (params?: any) => APIUtils.post('/api/package_data_code/add_package_data_codes_partner', params);
export const apiUpdatePackageDataCodePartner = (params?: any) => APIUtils.put('/api/package_data_code/update_package_data_code_partner', params);
// ---------------

// System
export const apiGetStatusMaintain = (params?: any) => APIUtils.get('/api/system/status_maintain', params);
export const apiUpdateStatusMaintain = (params?: any) => APIUtils.put('/api/system/status_maintain', params);
export const apiGetAccountDataSponsorCurrent = (params?: any) => APIUtils.get('/api/system/account_data_sponsor_current', params);
export const apiUpdateAccountDataSponsorCurrent = (params?: any) => APIUtils.put('/api/system/account_data_sponsor_current', params);
export const apiGetAccountAffApiCurrent = (params?: any) => APIUtils.get('/api/system/account_aff_api_current', params);
export const apiUpdateAccountAffApiCurrent = (params?: any) => APIUtils.put('/api/system/account_aff_api_current', params);
export const apiGetProviderCurrent = (params?: any) => APIUtils.get('/api/system/provider_current', params);
export const apiUpdateProviderCurrent = (params?: any) => APIUtils.put('/api/system/provider_current', params);

export const apiGetListMaintainScheduler = (params?: any) => APIUtils.get('/api/system/list_maintain_scheduler', params);
export const apiAddListMaintainScheduler = (params?: any) => APIUtils.post('/api/system/create_maintain_scheduler', params);
export const apiDeleteListMaintainScheduler = (data?: any) => APIUtils.delete(`/api/system/delete_maintain_scheduler`, data);

// Revenues APIS
export const apiGetListRevenue = (params: any) => APIUtils.get('/api/revenue/list', params);
export const apiDownloadDataSponsorReportDaily = (params: any) => APIUtils.get('/api/report/download_data_sponsor_report_daily', params, 'blob');
export const apiDownloadDataCodeReportDaily = (params: any) => APIUtils.get('/api/report/download_data_code_report_daily', params, 'blob');
export const apiDownloadRevenueReportDaily = (params: any) => APIUtils.get('/api/report/download_revenue_report_daily', params, 'blob');

export const apiGetMonthlyStats = (params: any) => APIUtils.get('/api/report/get_monthly_transaction_statistical', params);
export const apiGetDailyStats = (params: any) => APIUtils.get('/api/report/get_daily_transaction_statistical', params);

export const settingAPI = ({ onUnauthenticatedCallback }: { onUnauthenticatedCallback: Function }) => {
    overrideAxiosDefaultConfig();
    setupAxiosInterceptors(onUnauthenticatedCallback);
};