import { Line } from 'react-chartjs-2'

export default function Chart4({ data }: any) {
    return (
        <Line
            data={{
                labels: data.current?.map((item: any) => item.day),
                datasets: [
                    { label: 'Trong tháng', data: data.current?.map((item: any) => item.totalAmount) },
                    { label: 'Tháng trước', data: data.previous?.map((item: any) => item.totalAmount) },
                ],
            }}
            options={{
                responsive: true,
                plugins: {
                    legend: {
                        position: 'bottom',
                    },
                    tooltip: {
                        enabled: true,
                    },
                    title: {
                        display: false,
                    },
                    datalabels: {
                        display: false,
                    },
                },
                scales: {
                    y: {
                        beginAtZero: true,
                    },
                },
            }}
        />
    )
}
