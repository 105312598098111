import { useCallback, useEffect, useState } from "react"
import Chart from 'chart.js/auto'
import { LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Button, ConfigProvider, DatePicker, Divider, Form, InputNumber, Modal, Select, Space, Table } from "antd"
import { EyeOutlined, SearchOutlined, UndoOutlined } from '@ant-design/icons'
import vi_VN from 'antd/es/locale/vi_VN'
import dayjs from 'dayjs'
import Chart1 from "./Chart1"
import Chart2 from "./Chart2"
import Chart3 from "./Chart3"
import Chart4 from "./Chart4"
import { useLoading } from "../../context/LoadingContext"
import { apiGetListPartner, apiGetMonthlyStats } from "../../api"
import CustomTable from "../../components/table"
import { aggregateArrays, dataTypeColumns, dataTypeDayColumns, getInfoChart1, getInfoChart2, getInfoChart3, getInfoPackages, packageDayColumns, renderPackageMonthColumns, renderType } from "./method"
Chart.register(LineElement, CategoryScale, LinearScale, PointElement, ChartDataLabels)

export default function DashboardPage() {
	const [partnerOptions, setPartnerOptions] = useState<any>([])
	const [chart1, setChart1] = useState<any>({})
	const [chart2, setChart2] = useState<any>([])
	const [chart3, setChart3] = useState<any>({})
	const [packages, setPackages] = useState<any>([])
	const [packagesDay, setPackagesDay] = useState<any>([])
	const [modalDetailPackage, setModalDetailPackage] = useState<any>({ isOpen: false, data: null, packageName: '' })
	const [search, setSearch] = useState<any>({})
	const [formSearch] = Form.useForm()
	const { setIsLoading } = useLoading()

	const initSearch = () => {
		const date = dayjs()
		setSearch({
			currentYear: date?.year(),
			currentMonth: date?.month() + 1,
			previousYear: date.subtract(1, "month").year(),
			previousMonth: date.subtract(1, "month").month() + 1,
			day: date.subtract(1, "day").date(),
		})
	}

	useEffect(() => {
		initSearch()
	}, [])

	const fetchPartners = useCallback(async () => {
		try {
			const { data } = await apiGetListPartner()
			setPartnerOptions(data.partners?.map((item: any) => ({ label: item.name, value: item.cpCode })) || [])
		} catch (error) {
			console.log(error)
		}
	}, [])

	const fetchChartData = useCallback(async () => {
		if (!search.currentYear || !search.currentMonth || !search.previousYear || !search.previousMonth) return
		try {
			setIsLoading(true)
			const { data: current } = await apiGetMonthlyStats({
				year: search.currentYear,
				month: search.currentMonth,
			})
			const { data: previous } = await apiGetMonthlyStats({
				year: search.previousYear,
				month: search.previousMonth,
			})
			let currentData = current?.statistics, previousData = previous?.statistics
			let currentDay = [] as any, previousDay = [] as any
			if (search.cpCode) {
				currentData = currentData?.filter((item: any) => item.cpCode === search.cpCode)
				previousData = previousData?.filter((item: any) => item.cpCode === search.cpCode)
			}
			if (search.day > 0) {
				currentData = currentData?.filter((item: any) => {
					if (item.day === search.day) {
						currentDay.push(item)
					}
					return item.day <= search.day
				})
				previousData = previousData?.filter((item: any) => {
					if (item.day === search.day) {
						previousDay.push(item)
					}
					return item.day <= search.day
				})
			} else {
				const listDays = Array.from(new Set(currentData.map((item: any) => item.day)))
				previousData = previousData?.filter((item: any) => listDays.includes(item.day))
			}
			const dataChart1 = getInfoChart1(search.day > 0 ? currentDay : currentData)
			const dataChart2 = getInfoChart2(search.day > 0 ? currentDay : currentData, search.day > 0 ? previousDay : previousData)
			const dataChart3 = getInfoChart3(search.day > 0 ? currentDay : currentData)
			const revenueCurrent = getInfoPackages(currentData)
			const revenuePrevious = getInfoPackages(previousData)
			const revenueCurrentDay = getInfoPackages(currentDay)
			const revenuePreviousDay = getInfoPackages(previousDay)
			setChart1(dataChart1)
			setChart2(dataChart2)
			setChart3(dataChart3)
			setPackages(aggregateArrays(revenueCurrent, revenuePrevious))
			setPackagesDay(aggregateArrays(revenueCurrentDay, revenuePreviousDay))
		} catch (error) {
			console.log(error)
		} finally {
			setIsLoading(false)
		}
	}, [search])

	useEffect(() => {
		fetchPartners()
		fetchChartData()
	}, [fetchPartners, fetchChartData])

	const handleSearch = (values: any) => {
		const date = dayjs(values.date)
		setSearch({
			cpCode: values.cpCode || '',
			currentYear: date?.year(),
			currentMonth: date?.month() + 1,
			previousYear: date.subtract(1, "month").year(),
			previousMonth: date.subtract(1, "month").month() + 1,
			day: values.day || 0,
		})
	}

	const handleClearSearch = () => {
		formSearch.resetFields()
		initSearch()
	}

	const renderButtonDetailPackage = (data: any) => (
		<Button
			onClick={() => setModalDetailPackage({ isOpen: true, data: data.detail, packageName: `${renderType(data.dataType)} - ${data.packageName}` })}
			icon={<EyeOutlined />}
		>
		</Button>
	)

	return (
		<div>
			<Form
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'start',
					alignItems: 'center',
					marginBottom: 20,
				}}
				form={formSearch}
				onFinish={handleSearch}
				id="searchForm" layout="vertical" autoComplete="off">
				<div style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'start',
					flexWrap: 'wrap',
					gap: 20,
					width: '100%',
				}}>
					<Form.Item name="cpCode" label={<b>Đối tác</b>}>
						<Select
							style={{ minWidth: 170 }}
							placeholder="Chọn đối tác"
							defaultValue={''}
							options={[
								{ label: 'Tất cả', value: '' },
								...partnerOptions,
							]}
						/>
					</Form.Item>
					<ConfigProvider locale={vi_VN}>
						<Form.Item name="date" label="Tháng">
							<DatePicker
								defaultValue={dayjs()}
								format="YYYY-MM"
								picker="month"
							/>
						</Form.Item>
					</ConfigProvider>
					<Form.Item name="day" label="Ngày" initialValue={dayjs().subtract(1, "day").date()}>
						<InputNumber min={1} max={31} />
					</Form.Item>
					<Space>
						<Button type="primary" htmlType="submit" icon={<SearchOutlined />}>Tìm kiếm</Button>
						<Button onClick={handleClearSearch} icon={<UndoOutlined />}></Button>
					</Space>
				</div>
			</Form>
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
				{Object.keys(chart1)?.length > 0 && (
					<div style={{ flex: 2, maxWidth: '25%', minWidth: 260 }}>
						<Chart1 data={chart1} />
					</div>
				)}
				{chart2.length > 0 && (
					<div style={{ flex: 5, maxWidth: '50%', minWidth: 400 }}>
						<Chart2 data={chart2} />
					</div>
				)}
				{Object.keys(chart3)?.length > 0 && (
					<div style={{ flex: 2, maxWidth: '25%', minWidth: 260 }}>
						<Chart3 data={chart3} />
					</div>
				)}
			</div>
			<Divider />
			{packagesDay.length > 0 && (<>
				<h2 style={{ marginTop: 60 }}>Chi tiết doanh thu theo ngày</h2>
				<CustomTable
					namePage='nhóm gói'
					columns={search.day > 0 ? dataTypeDayColumns : dataTypeColumns}
					dataSource={packagesDay}
					total={packagesDay.length}
					expandable={{
						expandedRowRender: (record: any) => (
							<div style={{ marginBottom: 40, padding: 10, border: '1px solid grey', borderRadius: 10 }}>
								<Table
									size='small'
									rowKey={(_, index: any) => index}
									style={{ overflow: 'auto' }}
									columns={packageDayColumns}
									dataSource={record.detail}
									pagination={false}
								/>
							</div>
						),
					}}
				/>
			</>)}
			<h2 style={{ marginTop: 60 }}>Chi tiết doanh thu luỹ kế</h2>
			<CustomTable
				namePage='nhóm gói'
				columns={dataTypeColumns}
				dataSource={packages}
				total={packages.length}
				expandable={{
					expandedRowRender: (record: any) => (
						<div style={{ marginBottom: 40, padding: 10, border: '1px solid grey', borderRadius: 10 }}>
							<Table
								size='small'
								rowKey={(_, index: any) => index}
								style={{ overflow: 'auto' }}
								columns={renderPackageMonthColumns(renderButtonDetailPackage)}
								dataSource={record.detail}
								pagination={false}
							/>
						</div>
					),
				}}
			/>
			<Modal
				className="custom-modal-width"
				title={<h3>{modalDetailPackage.packageName}</h3>}
				open={modalDetailPackage.isOpen}
				onOk={() => alert()}
				onCancel={() => setModalDetailPackage({ isOpen: false, data: null, packageName: '' })}
				footer={[]}>
				{modalDetailPackage.isOpen && (
					<div>
						<Chart4 data={modalDetailPackage.data} />
					</div>
				)}
			</Modal>
		</div>
	)
}
