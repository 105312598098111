import { Bar } from 'react-chartjs-2'
import { formatCurrency } from '../../utils/string-utils'

export default function Chart2({ data }: any) {
    return (
        <Bar
            data={{
                labels: data.map((item: any) => item.name),
                datasets: [
                    {
                        label: "Số liệu trong tháng",
                        data: data.map((item: any) => item.current),
                    },
                    {
                        label: "Số liệu tháng trước",
                        data: data.map((item: any) => item.previous),
                    },
                ],
            }}
            options={{
                indexAxis: "y",
                scales: {
                    x: {
                        ticks: {
                            callback: (value: any) => {
                                if (value >= 1e9) return (value / 1e9) + ' Tỷ'
                                if (value >= 1e6) return (value / 1e6) + ' Tr'
                                if (value >= 1e3) return (value / 1e3) + ' N'
                                return value;
                            },
                        },
                    },
                },
                responsive: true,
                plugins: {
                    legend: {
                        position: 'bottom',
                    },
                    tooltip: {
                        callbacks: {
                            label: (context: any) => formatCurrency(context.raw),
                        },
                    },
                    title: {
                        display: true,
                        text: "So sánh doanh thu trong tháng n-1",
                        font: {
                            size: 16,
                        },
                        padding: {
                            bottom: 24,
                        },
                    },
                    datalabels: {
                        display: false,
                        formatter: (value: any) => formatCurrency(value),
                        font: {
                            weight: 'bold',
                            size: 14,
                        },
                    },
                },
            } as any}
        />
    )
}
