import { Pie } from 'react-chartjs-2'
import { formatCurrency } from '../../utils/string-utils'

export default function Chart3({ data }: any) {
    return (
        <Pie
            data={{
                labels: Object.keys(data),
                datasets: [{
                    data: Object.values(data),
                    hoverOffset: 4
                }]
            }}
            options={{
                responsive: true,
                plugins: {
                    legend: {
                        position: 'bottom',
                        align: 'start',
                    },
                    tooltip: {
                        callbacks: {
                            label: (context: any) => formatCurrency(context.raw),
                        },
                    },
                    title: {
                        display: true,
                        text: "Chi tiết doanh thu",
                        font: {
                            size: 16,
                        },
                        padding: {
                            bottom: 24,
                        },
                    },
                    datalabels: {
                        display: false,
                        formatter: (value: any) => formatCurrency(value),
                        font: {
                            weight: 'bold',
                            size: 14,
                        },
                    },
                },
            }}
        />
    )
}
